import {
  // @ts-ignore
  DateTimeType,
  // @ts-ignore
  DateType,
  // @ts-ignore
  DateTimeUnixType,
} from '@simbaze/api-helpers';

export enum UserRole {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  DEVELOPER = 'DEVELOPER',
  BOOKKEEPER = 'BOOKKEEPER',
  HR = 'HR',
  IMPERSONATE = 'IMPERSONATE',
}
export enum AttachmentType {
  CONTRACT = 'CONTRACT',
  CONTRACT_AMENDMENT = 'CONTRACT_AMENDMENT',
  NDA = 'NDA',
  INVOICE = 'INVOICE',
  ACT = 'ACT',
  CV = 'CV',
  OTHER = 'OTHER',
}
export enum FeeType {
  TAX = 'TAX',
  OTHER = 'OTHER',
}
export enum CompensationType {
  INSURANCE = 'INSURANCE',
  BONUS = 'BONUS',
  OTHER = 'OTHER',
}
export enum FeePeriod {
  PAYMENT = 'PAYMENT',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
}
export enum CurrencyCode {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
}
export enum Skills {
  JAVA = 'JAVA',
  SCALA = 'SCALA',
  DATA_SCIENCE = 'DATA_SCIENCE',
  DATA_ENGINEER = 'DATA_ENGINEER',
  MACHINE_LEARNING = 'MACHINE_LEARNING',
  JAVA_SCRIPT = 'JAVA_SCRIPT',
  REACT_JS = 'REACT_JS',
  DEV_OPS = 'DEV_OPS',
  DBA = 'DBA',
  CSS = 'CSS',
  QA = 'QA',
  QA_AUTOMATION = 'QA_AUTOMATION',
  TECHNICAL_WRITER = 'TECHNICAL_WRITER',
  UX_UI = 'UX_UI',
  C_NET = 'C_NET',
  PYTHON = 'PYTHON',
  PHP = 'PHP',
  AD_OPS = 'AD_OPS',
}
export enum PaymentPeriodType {
  TWO_WEEKS = 'TWO_WEEKS',
  FOUR_WEEKS = 'FOUR_WEEKS',
  TWICE_A_MONTH = 'TWICE_A_MONTH',
  MONTHLY = 'MONTHLY',
}
export enum PaymentType {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  FIXED = 'FIXED',
}
export enum ActivityType {
  INTRODUCING = 'INTRODUCING',
  ORGANIZATIONAL = 'ORGANIZATIONAL',
  FIRST_TECH_INTERVIEW = 'FIRST_TECH_INTERVIEW',
  SECOND_TECH_INTERVIEW = 'SECOND_TECH_INTERVIEW',
  FEEDBACK = 'FEEDBACK',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  OFFER = 'OFFER',
}
export enum Stage {
  WAITING_RESPONSE = 'WAITING_RESPONSE',
  INTERVIEW = 'INTERVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  WAITING_FEEDBACK = 'WAITING_FEEDBACK',
}
export type ClientDashboardDto = {
  developers: DashboardDeveloperDto[];
  invoices: DashboardInvoiceDto[];
  expenses: DashboardExpenseDto[];
};
export type DeveloperDashboardDto = {
  teammates: DashboardTeammateDto[];
  payments: DashboardPaymentDto[];
  earnings: DashboardEarningDto[];
};
export type BookkeeperDashboardDto = { invoices: DashboardInvoiceDto[]; payments: DashboardPaymentDto[] };
export type DashboardDeveloperDto = {
  userId?: string;
  firstName: string;
  lastName: string;
  rate?: number;
  currencyCode?: CurrencyCode;
  paymentType?: PaymentType;
  vacation: boolean;
  vacationStartDate?: string;
  vacationEndDate?: string;
  birthDay?: string;
  skills: Skills[];
};
export type DashboardInvoiceDto = { invoice: AccountInvoiceShortDto; attachment?: AttachmentDto };
export type DashboardExpenseDto = { month: string; amount: number; currency: CurrencyCode };
export type DashboardTeammateDto = {
  firstName: string;
  lastName: string;
  skills: Skills[];
  vacation: boolean;
  vacationStartDate?: string;
  vacationEndDate?: string;
  birthDay?: string;
};
export type DashboardPaymentDto = { payment: UserPaymentDto; attachment?: AttachmentDto };
export type DashboardEarningDto = { month: string; amount: number; currency: CurrencyCode };
export type EmailDto = {
  id: string;
  createdTime: DateTimeUnixType;
  subject: string;
  text: string;
  html: boolean;
  fromPerson: string;
  carbonCopies?: string;
  blindCarbonCopies?: string;
  toPersons: string;
  sent: boolean;
  error?: string;
};
export type EmailResponse = { total?: number; message?: string; items?: EmailDto[] };
export type PersonDto = { email: string; name?: string };
export type UserResponse = { total?: number; message?: string; items?: UserShortDto[] };
export type UserShortDto = {
  id: string;
  createdTime?: DateTimeUnixType;
  updatedTime?: DateTimeUnixType;
  email: string;
  lastName: string;
  firstName: string;
  active: boolean;
  phone?: string;
  birthDay?: string;
  timezone?: string;
  workingSinceDay?: string;
  verificationCode?: string;
  invitationCode?: string;
  acceptTokenAfter?: DateTimeUnixType;
  generatePayment?: boolean;
  paymentType?: PaymentType;
  paymentPeriodType?: PaymentPeriodType;
  currencyCode?: CurrencyCode;
  paymentCurrencyCode?: CurrencyCode;
};
export type TokenDto = { email: string; iat: DateTimeUnixType };
export type CredentialForm = { email: string; password: string };
export type UserDto = {
  id: string;
  createdTime?: DateTimeUnixType;
  updatedTime?: DateTimeUnixType;
  email: string;
  lastName: string;
  firstName: string;
  active: boolean;
  phone?: string;
  birthDay?: string;
  workingSinceDay?: string;
  verificationCode?: string;
  invitationCode?: string;
  acceptTokenAfter?: DateTimeUnixType;
  timezone: string;
  hubstaffId?: number;
  currencyCode?: CurrencyCode;
  paymentCurrencyCode?: CurrencyCode;
  paymentType?: PaymentType;
  paymentPeriodType?: PaymentPeriodType;
  wage?: number;
  generatePayment: boolean;
  totalVacationDays?: number;
  roles: UserRole[];
  skills?: Skills[];
  accountRates?: AccountRateDto[];
  serviceFees?: ServiceFeeDto[];
  compensations?: CompensationDto[];
  address?: AddressDto;
  invoiceDetails?: InvoiceDetailsDto;
  accounts: AccountShortDto[];
  attachments: AttachmentDto[];
};
export type UserForm = {
  email: string;
  password: string;
  repeatPassword: string;
  lastName: string;
  firstName: string;
  phone?: string;
  birthDay?: string;
  workingSinceDay?: string;
  timezone: string;
  hubstaffId?: number;
  currencyCode?: CurrencyCode;
  paymentCurrencyCode?: CurrencyCode;
  paymentType?: PaymentType;
  paymentPeriodType?: PaymentPeriodType;
  wage?: number;
  generatePayment: boolean;
  totalVacationDays?: number;
  roles: UserRole[];
  skills?: Skills[];
  accountRates?: AccountRateForm[];
  serviceFees?: ServiceFeeForm[];
  compensations?: CompensationForm[];
  address?: AddressForm;
  invoiceDetails?: InvoiceDetailsForm;
  accountIds: string[];
  attachmentIds: string[];
};
export type UserProfileForm = {
  password?: string;
  repeatPassword?: string;
  lastName: string;
  firstName: string;
  phone?: string;
  birthDay?: string;
  timezone: string;
  address?: AddressForm;
};
export type UserActivationForm = {
  password: string;
  repeatPassword: string;
  firstName: string;
  lastName: string;
  phone?: string;
  timezone: string;
  birthDay?: string;
};
export type AccountResponse = { total?: number; message?: string; items?: AccountDto[] };
export type AccountDto = {
  id: string;
  createdTime?: DateTimeUnixType;
  updatedTime?: DateTimeUnixType;
  name: string;
  email: string;
  invoiceCurrencyCode: CurrencyCode;
  paymentPeriodType: PaymentPeriodType;
  agencyFee: number;
  generateInvoice?: boolean;
  address?: AddressDto;
  invoiceDetails?: InvoiceDetailsDto;
  hubstaffIds?: number[];
  attachments: AttachmentDto[];
};
export type AccountShortDto = {
  id: string;
  name: string;
  email: string;
  agencyFee?: number;
  invoiceCurrencyCode?: CurrencyCode;
};
export type AccountForm = {
  name: string;
  email: string;
  invoiceCurrencyCode: CurrencyCode;
  paymentPeriodType: PaymentPeriodType;
  agencyFee: number;
  generateInvoice?: boolean;
  address?: AddressForm;
  invoiceDetails?: InvoiceDetailsForm;
  hubstaffIds?: number[];
  attachmentIds: string[];
};
export type AccountRateForm = {
  accountId: string;
  rate: number;
  agencyFee: number;
  currencyCode: CurrencyCode;
  paymentType: PaymentType;
  paidVacation?: boolean;
};
export type ServiceFeeForm = { fee: number; feePeriod: FeePeriod; currencyCode: CurrencyCode; feeType: FeeType };
export type CompensationForm = {
  amount: number;
  feePeriod: FeePeriod;
  currencyCode: CurrencyCode;
  compensationType: CompensationType;
};
export type AccountRateDto = {
  account: AccountShortDto;
  rate: number;
  agencyFee: number;
  currencyCode: CurrencyCode;
  paymentType: PaymentType;
  paidVacation?: boolean;
};
export type ServiceFeeDto = { fee: number; feePeriod: FeePeriod; currencyCode: CurrencyCode; feeType: FeeType };
export type CompensationDto = {
  amount: number;
  feePeriod: FeePeriod;
  currencyCode: CurrencyCode;
  compensationType: CompensationType;
};
export type CandidateResponse = { total?: number; message?: string; items?: CandidateDto[] };
export type CandidateDto = {
  id: string;
  createdTime?: DateTimeUnixType;
  updatedTime?: DateTimeUnixType;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  skype?: string;
  telegram?: string;
  rate?: string;
  comment?: string;
  stage: Stage;
  profile?: string;
  skills?: Skills[];
  attachments: AttachmentDto[];
};
export type CandidateForm = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  skype?: string;
  telegram?: string;
  rate?: string;
  comment?: string;
  stage: Stage;
  profile?: string;
  skills?: Skills[];
  attachmentIds: string[];
};
export type UserVacationForm = { startDate: string; endDate: string; paid?: boolean; userId?: string };
export type UserVacationDto = {
  id: string;
  createdTime?: DateTimeUnixType;
  updatedTime?: DateTimeUnixType;
  startDate: string;
  endDate: string;
  paid?: boolean;
  usedVacationDays: number;
  user?: UserShortDto;
};
export type UserVacationResponse = { total?: number; message?: string; items?: UserVacationDto[] };
export type CandidateActivityDto = {
  id: string;
  createdTime: DateTimeUnixType;
  updatedTime: DateTimeUnixType;
  text: string;
  appointedTime?: DateTimeUnixType;
  activityType: ActivityType;
  processed?: boolean;
  candidate: CandidateDto;
};
export type CandidateActivityResponse = { total: number; message?: string; items: CandidateActivityDto[] };
export type CandidateActivityForm = { text: string; appointedTime?: DateTimeUnixType; activityType: ActivityType };
export type UserPaymentResponse = { total?: number; message?: string; items?: UserPaymentDto[] };
export type UserPaymentDto = {
  id: string;
  createdTime?: DateTimeUnixType;
  updatedTime?: DateTimeUnixType;
  paymentPeriod: PaymentPeriodDto;
  user: UserShortDto;
  comment?: string;
  paymentCurrencyCode: CurrencyCode;
  paid: boolean;
  paymentAmount: number;
  amountByAccountsExchanged: number;
  modificationsExchanged: number;
  amountByAccounts: AmountByAccountDto[];
  amountTotal?: number;
  attachments: AttachmentDto[];
  modifications: UserPaymentModificationDto[];
};
export type UserPaymentForm = {
  userId: string;
  paymentPeriodId: string;
  comment?: string;
  paymentCurrencyCode: CurrencyCode;
  amountByAccounts: AmountByAccountForm[];
  attachmentIds: string[];
  modifications: UserPaymentModificationForm[];
};
export type ExchangeRateDto = { fromCurrency: CurrencyCode; toCurrency: CurrencyCode; rate: number };
export type PaymentPeriodDto = {
  id: string;
  createdTime: DateTimeUnixType;
  updatedTime: DateTimeUnixType;
  startDate: string;
  endDate: string;
  paymentPeriodType: PaymentPeriodType;
  exchangeRateDtos?: ExchangeRateDto[];
};
export type AmountByAccountForm = {
  accountId: string;
  paymentType?: PaymentType;
  currencyCode: CurrencyCode;
  rate: number;
  loggedDays: number;
  loggedTimeByDates: AccountLoggedTimeDto[];
};
export type AmountByAccountDto = {
  account: AccountShortDto;
  paymentType: PaymentType;
  currencyCode: CurrencyCode;
  paymentCurrencyCode: CurrencyCode;
  rate: number;
  exchangeRate: number;
  amount: number;
  amountExchanged: number;
  loggedDays: number;
  loggedTimeByDates: AccountLoggedTimeDto[];
};
export type AccountLoggedTimeDto = { date: string; loggedTime: number };
export type AccountLoggedTimeForm = { accountId: string; date: DateTimeUnixType; loggedTime: number };
export type CandidateCVDto = {
  id: string;
  createdTime: DateTimeUnixType;
  updatedTime: DateTimeUnixType;
  reference: string;
  name: string;
  candidate: CandidateDto;
};
export type CandidateCVResponse = { total: number; message?: string; items: CandidateCVDto[] };
export type FileDto = { name: string; content: string };
export type AttachmentDto = {
  id: string;
  createdTime: DateTimeUnixType;
  updatedTime: DateTimeUnixType;
  reference: string;
  name: string;
  attachmentType: AttachmentType;
};
export type AccountInvoiceForm = {
  paymentPeriodId: string;
  accountId: string;
  comment?: string;
  userLoggedTimes: UserLoggedTimeForm[];
  userLoggedDays: UserLoggedDayForm[];
  userFixedPayments: UserFixedPaymentForm[];
  userVacationPayments: UserVacationPaymentForm[];
  modifications: AccountInvoiceModificationForm[];
  attachmentIds: string[];
  invoiceCurrencyCode: CurrencyCode;
  agencyFee: number;
};
export type UserLoggedTimeForm = {
  userId: string;
  loggedTime: number;
  agencyFee?: number;
  rate?: number;
  currencyCode?: CurrencyCode;
};
export type UserLoggedDayForm = {
  userId: string;
  loggedDays: number;
  agencyFee?: number;
  rate?: number;
  currencyCode?: CurrencyCode;
};
export type UserFixedPaymentForm = {
  userId: string;
  amountNoFee: number;
  agencyFee?: number;
  currencyCode: CurrencyCode;
};
export type UserVacationPaymentForm = {
  userId: string;
  vacationDays: number;
  currencyCode?: CurrencyCode;
  rate?: number;
  avgHoursWork?: number;
  amount?: number;
  amountNoFee?: number;
  agencyFee?: number;
};
export type AccountInvoiceModificationForm = {
  message: string;
  amount?: number;
  amountNoFee: number;
  agencyFee?: number;
  currencyCode: CurrencyCode;
};
export type UserPaymentModificationForm = { message: string; currencyCode: CurrencyCode; amount: number };
export type AccountInvoiceModificationDto = {
  message: string;
  amount: number;
  currencyCode: CurrencyCode;
  amountNoFee: number;
  agencyFee: number;
  exchangeRate: number;
  amountExchanged: number;
  invoiceCurrencyCode: CurrencyCode;
};
export type UserPaymentModificationDto = {
  message: string;
  currencyCode: CurrencyCode;
  paymentCurrencyCode: CurrencyCode;
  amount: number;
  exchangeRate: number;
  amountExchanged: number;
};
export type AccountInvoiceDto = {
  id: string;
  createdTime: DateTimeUnixType;
  updatedTime: DateTimeUnixType;
  paymentPeriod: PaymentPeriodDto;
  account: AccountShortDto;
  comment?: string;
  amount: number;
  amountNoFee: number;
  amountTotal?: number;
  feeAmount: number;
  agencyFee: number;
  hourlyWagesAmountExchanged: number;
  hourlyWagesAmountNoFeeExchanged: number;
  hourlyWagesFeeExchanged: number;
  dailyWagesAmountExchanged: number;
  dailyWagesAmountNoFeeExchanged: number;
  dailyWagesFeeExchanged: number;
  fixedPaymentsAmountExchanged: number;
  fixedPaymentsAmountNoFeeExchanged: number;
  fixedPaymentsFeeExchanged: number;
  vacationPaymentsAmountExchanged: number;
  vacationPaymentsAmountNoFeeExchanged: number;
  vacationPaymentsFeeExchanged: number;
  modificationsAmountExchanged: number;
  modificationsAmountNoFeeExchanged: number;
  modificationsFeeExchanged: number;
  paid: boolean;
  invoiceCurrencyCode?: CurrencyCode;
  hourlyWages?: HourlyWageDto[];
  dailyWages?: DailyWageDto[];
  fixedPayments?: FixedPaymentDto[];
  vacationPayments?: VacationPaymentDto[];
  modifications: AccountInvoiceModificationDto[];
  attachments: AttachmentDto[];
};
export type DailyWageDto = {
  user: UserShortDto;
  loggedDays: number;
  currencyCode: CurrencyCode;
  amount: number;
  amountNoFee: number;
  agencyFee: number;
  rate: number;
  exchangeRate: number;
  amountExchanged: number;
  invoiceCurrencyCode: CurrencyCode;
};
export type HourlyWageDto = {
  user: UserShortDto;
  loggedTime: number;
  currencyCode: CurrencyCode;
  amount: number;
  amountNoFee: number;
  agencyFee: number;
  rate: number;
  exchangeRate: number;
  amountExchanged: number;
  invoiceCurrencyCode: CurrencyCode;
};
export type FixedPaymentDto = {
  user: UserShortDto;
  currencyCode: CurrencyCode;
  amount: number;
  amountNoFee: number;
  agencyFee: number;
  exchangeRate: number;
  amountExchanged: number;
  invoiceCurrencyCode: CurrencyCode;
  paymentFromDate: string;
  paymentToDate: string;
};
export type VacationPaymentDto = {
  user: UserShortDto;
  vacationDays: number;
  currencyCode: CurrencyCode;
  amount: number;
  amountNoFee: number;
  rate: number;
  avgHoursWork: number;
  agencyFee: number;
  exchangeRate: number;
  amountExchanged: number;
  invoiceCurrencyCode: CurrencyCode;
};
export type AccountInvoiceShortDto = {
  id: string;
  createdTime: DateTimeUnixType;
  updatedTime: DateTimeUnixType;
  startDate: string;
  endDate: string;
  amount: number;
  amountNoFee: number;
  amountTotal?: number;
  agencyFee: number;
  paid: boolean;
  invoiceCurrencyCode: CurrencyCode;
  account: AccountShortDto;
};
export type AccountInvoiceResponse = { total: number; message?: string; items: AccountInvoiceShortDto[] };
export type AddressForm = { country?: string; city?: string; street?: string; flatNumber?: string; zip?: string };
export type InvoiceDetailsForm = {
  invoiceName?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bicCode?: string;
};
export type AddressDto = { country?: string; city?: string; street?: string; flatNumber?: string; zip?: string };
export type InvoiceDetailsDto = {
  invoiceName?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bicCode?: string;
};
export type AccountInvoiceFilter = {
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
  paymentPeriodId?: string;
  paymentPeriodType: PaymentPeriodType;
  accountIds?: string[];
  onlyUnpaid?: boolean;
};
export type PaymentPeriodResponse = { total?: number; message?: string; items?: PaymentPeriodDto[] };
export type UserRateDto = {
  user: UserShortDto;
  rate: number;
  agencyFee?: number;
  currencyCode: CurrencyCode;
  paymentType: PaymentType;
};
export type UserPaymentsFilter = {
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
  paymentPeriodId?: string;
  paymentPeriodType: PaymentPeriodType;
  userIds?: string[];
  onlyUnpaid?: boolean;
};
export type CandidateFilter = {
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
  filter?: string;
  stage?: Stage;
  skills?: Skills[];
};
export type UserVacationStatisticsDto = { daysTotal?: number; daysLeft?: number };
export type UserVacationUserResponse = { total?: number; message?: string; items?: UserVacationUser[] };
export type UserVacationUser = { user: UserShortDto; subscribed: boolean };
export type NotificationDto = {
  id: string;
  createdTime: DateTimeUnixType;
  createdBy: UserShortDto;
  subject: string;
  message: string;
  read: boolean;
  receivers?: UserShortDto[];
};
export type NotificationResponse = { total?: number; message?: string; items?: NotificationDto[] };
export type NotificationForm = { subject: string; message: string; userIds: string[] };
export type TicketForm = { text: string; attachmentIds?: string[] };
export type TicketMessageForm = { text: string; attachmentIds?: string[] };
export type TicketDto = {
  id: string;
  createdTime: DateTimeUnixType;
  updatedTime: DateTimeUnixType;
  createdBy: UserShortDto;
  updatedBy: UserShortDto;
  text: string;
  messagesCount: number;
  opened: boolean;
  attachments: AttachmentDto[];
};
export type TicketResponse = { total?: number; message?: string; items?: TicketDto[] };
export type TicketMessageDto = {
  id: string;
  createdTime: DateTimeUnixType;
  createdBy: UserShortDto;
  text: string;
  attachments: AttachmentDto[];
};
export type TicketMessageResponse = { total?: number; message?: string; items?: TicketMessageDto[] };
export type AccountInvoiceHistoryResponse = { total?: number; message?: string; items?: AccountInvoiceHistoryDto[] };
export type AccountInvoiceHistoryDto = {
  id: string;
  createdTime: DateTimeUnixType;
  createdBy: UserShortDto;
  field: string;
  oldValue: string;
  newValue: string;
};
export type UserPaymentHistoryResponse = { total?: number; message?: string; items?: UserPaymentHistoryDto[] };
export type UserPaymentHistoryDto = {
  id: string;
  createdTime: DateTimeUnixType;
  createdBy: UserShortDto;
  field: string;
  oldValue: string;
  newValue: string;
};
